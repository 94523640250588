<template>
  <div class="page-container">
    <div>
      <div class="shadow-layer">
        <div class="container">
          <div class="nav-box">
            <div class="nav-panel">
              <div class="nav-label">机械类型：</div>
              <div :class="jiexie == false ? 'nav-list' : 'nav-list1'">
                <div
                  class="item header-cursor"
                  v-for="(item, index) in nav1"
                  :key="index"
                >
                  <div
                    :class="currentNavIndex1 === index ? 'active' : ''"
                    @click="handleChangeNav(item, index)"
                  >
                    {{ item.vehicleTypeName }}
                  </div>
                </div>
              </div>
              <div class="dow" @click="isShowjixie(1)">
                <img
                  :class="jiexie == true ? 'downIcon' : 'rotateUp'"
                  src="../../assets/images/dow.png"
                  alt=""
                />
              </div>
            </div>
            <div class="nav-panel">
              <div class="nav-label">机械品牌：</div>
              <div :class="pingpai == false ? 'nav-list' : 'nav-list1'">
                <div
                  class="item header-cursor"
                  v-for="(item, index) in nav2"
                  :key="index"
                >
                  <span
                    @click="handleChangeNav2(item, index)"
                    :class="currentNavIndex2 === index ? 'active' : ''"
                  >
                    {{ item.brandName }}
                  </span>
                </div>
              </div>
              <div class="dow" @click="isShowjixie(2)">
                <img
                  :class="pingpai == true ? 'downIcon' : 'rotateUp'"
                  src="../../assets/images/dow.png"
                  alt=""
                />
              </div>
            </div>
            <div class="nav-panel">
              <div class="nav-label">机械地址：</div>
              <div :class="address == false ? 'nav-list' : 'nav-list1'">
                <div
                  class="item header-cursor"
                  v-for="(item, index) in nav3"
                  :key="index"
                >
                  <span
                    @click="handleChangeNav3(item, index)"
                    :class="currentNavIndex3 === index ? 'active' : ''"
                  >
                    {{ item.cityName }}
                  </span>
                </div>
              </div>
              <div class="dow" @click="isShowjixie(3)">
                <img
                  :class="address == true ? 'downIcon' : 'rotateUp'"
                  src="../../assets/images/dow.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="result-content">
        <el-row :gutter="30">
          <el-col :span="30">
            <div class="list-box" v-if="list.length > 0">
              <div
                class="item"
                v-for="(item, index) in list"
                :key="index"
                @click="toDetail(item)"
              >
                <div class="cot">
                  <el-image fit="contain" :src="item.vehicleImgs[0]"></el-image>
                  <div class="title">{{ item.vehicleName }}</div>
                  <div class="adres">
                    <div>
                      <span>{{ item.offer }}</span
                      >元{{
                        item.leaseTypeCode == 3 || item.leaseTypeCode == null
                          ? ""
                          : "/" + item.leaseType
                      }}
                    </div>
                    <div>
                      <i class="el-icon-map-location"></i>
                      {{ item.vehicleProvince }}/{{ item.vehicleCounty }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="pt" v-if="list.length > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
    </div>
    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import elFooter from "@/components/footer";
import { vehicleTypeTree, getBrand, getCityTree } from "@/api/user";
import { getVehicleInfoByPage, getVehicleInfoByPageOpen } from "@/api/car";
export default {
  data() {
    return {
      total: 1331,
      currentNavIndex1: 0,
      currentNavIndex2: 0,
      currentNavIndex3: 0,
      bg3: require("@/assets/images/page-top-bg.png"),
      bg4: require("@/assets/images/page-right-bg.png"),
      nav1: [],
      nav2: [],
      nav3: [],
      vehicleParam: {
        firstLevelType: null,
        page: 1,
        pageSize: 8,
        vehicleBrand: null,
        vehicleProvinceCode: null,
      },
      list: [],
      jiexie: false,
      pingpai: false,
      address: false,
    };
  },
  components: { elFooter },
  created() {
    vehicleTypeTree().then((res) => {
      this.nav1 = res.data;
      this.nav1.unshift({
        vehicleTypeName: "全部",
        vehicleTypeId: null,
      });
      if (this.$route.query.id) {
        this.nav1.forEach((item, index) => {
          if (item.vehicleTypeId == this.$route.query.id) {
            this.vehicleParam.firstLevelType = this.$route.query.id;
            this.currentNavIndex1 = index;
          }
        });
      }
    });
    getBrand().then((res) => {
      this.nav2 = res.data;
      this.nav2.unshift({
        brandName: "全部",
        id: null,
      });
    });
    getCityTree().then((res) => {
      this.nav3 = res.data;
      this.nav3.unshift({
        cityName: "全国",
        cityId: null,
      });
    });
    this.getVehicleInfoByPage();
  },
  methods: {
    getVehicleInfoByPage() {
      setTimeout(() => {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // accountType 1 普通账号  2  车主账号 3 企业账号
        let apiPost =
          userInfo.accountType == 3
            ? getVehicleInfoByPageOpen
            : getVehicleInfoByPage;
        apiPost(this.vehicleParam).then((res) => {
          this.list = res.data.list.filter((e) => e.checkStatus != "0");
          this.total = res.data.total;
        });
      }, 500);
    },
    handleChangeNav(item, index) {
      this.vehicleParam.firstLevelType = item.vehicleTypeId;
      this.currentNavIndex1 = index;
      this.getVehicleInfoByPage();
    },
    handleChangeNav2(item, index) {
      this.vehicleParam.vehicleBrand = item.id;
      this.currentNavIndex2 = index;
      this.getVehicleInfoByPage();
    },
    handleChangeNav3(item, index) {
      this.vehicleParam.vehicleProvinceCode = item.cityId;
      this.currentNavIndex3 = index;
      this.getVehicleInfoByPage();
    },
    toDetail(item) {
      this.$router.push({ path: `/result/detail1`, query: { id: item.id } });
    },
    handleCurrentChange(val) {
      this.vehicleParam.page = val;
      this.getVehicleInfoByPage();
    },
    isShowjixie(index) {
      if (index == 1) {
        this.jiexie = !this.jiexie;
      } else if (index == 2) {
        this.pingpai = !this.pingpai;
      } else {
        this.address = !this.address;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-pagination {
  margin-left: auto;
}

.page-container {
  background-color: #fff;
}

.shadow-layer {
  padding: 20px 0;
  background-color: #fff;

  .nav-box {
    margin-top: 20px;
    font-size: 14px;
    color: #666666;

    .nav-panel {
      display: flex;
      margin-bottom: 20px;

      .dow {
        .downIcon {
          width: 25px;
          height: 25px;
          transition: transform 0.35s;
          cursor: pointer;
        }

        .rotateUp {
          width: 25px;
          height: 25px;
          transition: transform 0.35s;
          transform: rotate(180deg);
          cursor: pointer;
        }
      }

      .nav-list1 {
        display: flex;
        flex-wrap: wrap;
        flex: 1;

        .item {
          min-width: 100px;
          text-align: center;
          margin-bottom: 10px;

          &.header-cursor {
            cursor: pointer;
            transition: all 0.5s;
          }

          &.active {
            color: #fcb110;
          }

          .active {
            color: #fcb110;
          }
        }
      }

      .nav-list {
        display: flex;
        overflow: hidden;
        flex: 1;

        .item {
          min-width: 100px;
          text-align: center;

          &.active {
            color: #fcb110;
          }

          &.header-cursor {
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
              color: #fcb110;
            }
          }

          .active {
            color: #fcb110;
          }
        }
      }
    }
  }
}

.total-num {
  font-size: 14px;
  color: #666666;
  line-height: 50px;
}

.pt {
  padding: 40px 0 40px 0;
  display: flex;
  justify-content: right;
}

.list-box {
  display: flex;
  flex-wrap: wrap;
  color: #666;
  font-size: 14px;

  .item {
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
    border: solid 1px #DDDDDD;
    padding: 10px;

    .cot {
      .el-image {
        width: 268px;
        height: 239px;
      }

      .title {
        margin: 10px 0;

        &:hover {
          color: #fcb110;
        }
      }

      .adres {
        display: flex;
        justify-content: space-between;

        span {
          color: #FC4610;
          font-size: 16px;
        }
      }
    }
  }
}
</style>